import React from "react"

import "./Welcome.css"
import Logo from "../../assets/images/logo.png"
import About from "./About"

const Welcome = ({ element }) => {
    return (
        <main>
            <section className="welcome">
                <div ref={element}>
                    <img src={Logo} alt="logo" className="welcome--logo" />
                    <p>Hello... My name is Ole Hansen<br />OhhhDEV.</p>
                    <button className="welcome__cta-primary">Contact Me</button>
                </div>
            </section>
            <About />
            <About />
            <About />
            <About />
            <About />
            <About />
        </main>
    )
};

export default Welcome;